<template>
  <div>
    <headerSection mainMenu="partner"></headerSection>
    <div class="x-main-content" :style="{ 'height':'100%','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}"><!--,'background-size':'900px 700px','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})` -->
      <div id="main_content_recommend" class="container">
    <section class="row x-category-index" >
        <div class="col-sm-12 col-lg-12 col-12 recommend_section" style="padding-top:25px;">
            <fieldset class="form-group text-center mb-0 px-1">
                <div class="row mt-2 mb-0 mb-4 justify-content-md-center">
                    <div class="col-sm-12 col-lg-12 col-12" style="padding-top: 45px;">
                        <h2 class="text-center mb-3" style="font-weight: 500;color:#fff;">ลิงก์รับทรัพย์</h2>
                        <img src="https://firebasestorage.googleapis.com/v0/b/banana-789.appspot.com/o/images%2F-NzEx04ejPczvbouFN_q.jpg?alt=media&token=237eefdb-77e9-4572-97db-e80bad4608f5" class="-cover-img img-fluid" style="padding-bottom:20px;">
                        <div role="group" class="input-group">
                                <input id="copyTarget3" type="text" readonly="readonly" class="form-size-14 form-control" :value="`https://banana789.win?ref=${user.code}`">
                            <div class="input-group-append">
                                <button id="copyButton3" class="btn btn-copy-link btn-sm btn_bg" v-clipboard:copy="`https://banana789.win?ref=${user.code}`" v-clipboard:success="clipboardSuccessHandler"><i class="fa fa-copy fa-sm mr-1"></i>คัดลอก </button>
                            </div>
                        </div>
                        <!--
                        <fieldset class="form-group">
                            <div class="row mt-3 mb-0 justify-content-md-center">
                                    <div class="col-6">
                                        <a href="https://social-plugins.line.me/lineit/share?url=https://auto.shib1688.net/register?uplineid=NjI1NDI=" target="_blank" class="btn btn-line btn-sm btn-block"><img src="https://auto.shib1688.net/assets/images/icon-line.png" style="max-width: 15px;display: inline-block;margin-right: 3px;"> แชร์ </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://auto.shib1688.net/register?uplineid=NjI1NDI=" target="_blank" class="btn btn-facebook btn-sm btn-block"><i class="fa fa-facebook-square mr-1" aria-hidden="true"></i> แชร์ </a>
                                    </div>
                            </div>
                        </fieldset>
                        -->
                        <p class="text-white mt-4 mb-0" style="font-family: Kanit;"> ส่ง Link ได้ทุกช่องทางรับทรัพย์กับเรา </p>
                    </div>
                </div>
            </fieldset>

            <div class="text-title text-center" style="font-family: Kanit;">รายได้ผู้แนะนำ</div>
            <div class="row no-gutters">
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">แนะนำเพื่อน</p>
                        <p class="mb-0">{{(this.level1)?Object.keys(this.level1).length:0}}</p>
                        <img src="../assets/images/analysis.png" width="30" style="cursor:pointer" @click="showlevel(1)">
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">เพื่อนแนะนำเพื่อน</p>
                        <p class="mb-0">{{(this.level2)?Object.keys(this.level2).length:0}}</p>
                        <img src="../assets/images/analysis.png" width="30" style="cursor:pointer" @click="showlevel(2)">
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">รายได้แนะนำเพื่อน</p>
                        <p class="mb-0">{{ (partner && partner.total1)?numberWithCommas(partner.total1):0}}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">รายได้เพื่อนแนะนำเพื่อน</p>
                        <p class="mb-0">{{ (partner && partner.total2)?numberWithCommas(partner.total2):0}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">รายได้ทั้งหมด</p>
                        <p class="mb-0">{{ (partner && partner.total)?numberWithCommas(partner.total):0}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center" style="padding-top:22px;">
                    <a href="javascript:void(0)" @click="movePartnerToWallet()" class="text-white js-account-approve-aware btn" :style="checkStyle()" data-toggle="modal">
                        <div style="font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">โอนเงินเข้ากระเป๋าหลัก</div>
                    </a>
                </div>
        </div>
        <!-- <div class="col-sm-12 col-lg-12 col-12 recommend_section">
            <p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;">
                <b class="tt_l tt_full fr_tx1" style="font-size:20px;">ลิ้งค์ช่วยแชร์รับ <span style="color:#FAB537">0.8%  </span>  ฟรี ทุกยอดการแทง</b> (แค่ก๊อปปี้ลิ้งค์ไปแชร์ก็ได้เงินแล้ว) <span style="color:#FAB537">ยิ่งแชร์มากยิ่งได้มาก</span><br><br>
                                    ท่านสามารถนำลิ้งค์ด้านล่างนี้หรือนำป้ายแบนเนอร์ ไปแชร์ในช่องทางต่างๆ ไม่ว่าจะเป็น เว็บไชต์ส่วนตัว, Blog, Facebook หรือ Social Network อื่นๆ หากมีการสมัครสมาชิกโดยคลิกผ่านลิ้งค์ของท่านเข้ามา ลูกค้าที่สมัครเข้ามาก็จะอยู่ภายใต้เครือข่ายของท่านทันที และหากลูกค้าภายใต้เครือข่ายของท่านมีการเดิมพันเข้ามา ทุกยอดการเดิมพัน ท่านจะได้รับส่วนแบ่งในการแนะนำ 0.8% ทันทีโดยไม่มีเงื่อนไข
                                    <br><br><u>ตัวอย่างเช่น...</u>

                                - ลูกค้าท่าน 1 คน แทง 1,000 บาท ท่านจะได้ 8 บาท (ท่านจะได้ทุกรายการแทงของลูกค้า)
                                <br>
                                * <span style="color:#FAB537">ทุกวัน เวลา 14.00 น.</span> ระบบจะทำการคำนวณยอด เช่น แนะนำสมาชิกได้และสมาชิกมียอดเล่นรวมทั้งหมด (200,000 x 0.8%)/100 = <span style="color:#FAB537">1,600.00 บาท/วัน</span> เป็นรายได้แบบถาวร
                                ยิ่งแนะนำเยอะ ยิ่งได้เยอะ โยกเงินเข้ากระเป๋าหลักได้ทันที เพียงแค่ชวนมาอยู่ภายใต้ท่านท่านกินค่าคอมมิชชั่น<span style="color:#FAB537">ฟรีไปตลอดชีวิต</span>
                                <br>
สามารถทำรายได้เดือน 100,000 บาทง่ายๆเลยทีเดียว และรายได้ทุกบาททุกสตางค์ของท่านสามารถตรวจสอบได้ทุกขั้นตอน แจกจริง จ่ายจริง ที่นี่ที่เดียวที่ให้คุณมากกว่าใคร ก๊อปปี้ลิ้งค์นำไปแชร์ได้เลย
            </p>
        </div> -->
    </section>
</div>
</div>


<div data-loading-container=".modal-body" tabindex="-1" class="modal x-modal -promotion-detail-modal promotion-detail-modal-27 show" aria-modal="true" :style="`display: ${(showdetail)?`block`:'none'}; padding-right: 8px;`"><div class="modal-dialog -modal-size  " role="document" style="padding-top: 152.8px;max-width: 600px;">
    <div class="modal-content -modal-content" :style="{ 'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}">
                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close" @click="closeDetail()">
                <i class="fas fa-times"></i>
            </button>
    <div class="modal-body -modal-body" style="top: 0px;">
        <div class="container">
            <div class="row">
                <div class="col-12 mt-4">
                    <div class="x-page-title-component ">
                      <div class="-inner-wrapper">
                        <h1 class="-title">{{titleDetail}}</h1>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column">
          <div class="-real-content">
                    
            <div class="x-card card -card-promotion-detail ">
                                            
            <div style="padding-top:20px;">
                <font color="#fff">*ข้อมูลสรุปยอดเมื่อวาน</font>
                <div class="x-promotion-content">
                  <div class="fixTableHead">
                  <table class="tablecontainer">
                    <thead>
                      <tr>
                        <th><h1 style="text-align:center">รหัส</h1></th>
                        <th><h1 style="text-align:center">วันที่</h1></th>
                        <th><h1 style="text-align:center">ฝาก</h1></th>
                        <th><h1 style="text-align:center">ถอน</h1></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in history" :key="index">
                        <td style="text-align:center">{{item.friend}}</td>
                        <td style="text-align:center">{{item.createdateShow}}</td>
                        <td style="text-align:right">{{ numberWithCommas(item.deposit) }}</td>
                        <td style="text-align:right">{{ numberWithCommas(item.withdraw) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
            </div>

                            <div class="card-footer">
                    <!-- <button class="btn -btn -get-promotion-btn js-promotion-apply " data-toggle="modal" data-target="#depositModal" data-promotion-target="promotion-detail-modal-27" data-type="deposit" data-dismiss="modal" data-url="/promotion/27/apply">
                        <span>รับโปรโมชั่น</span>
                    </button> -->
                </div>
                        </div>
                </div>
                                    <div class="mx-3 mb-3">
                        <div class="x-admin-contact -no-fixed">
                            <span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
     <label class="-text-message ">ติดปัญหา&nbsp;</label>&nbsp;
     <a href="https://lin.ee/ngKyQj96" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
                        </div>
                    </div>
                                    </div>
            </div>
    </div>
</div></div>

<footerSection></footerSection>
</div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
import moment from 'moment-timezone'
const axios = require('axios').default
export default {
  name: 'Partner',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  created () {
	  const user = localStorage.getItem('userData')
	  if (user) {
		  const _user = JSON.parse(user)
		  this.$store.dispatch('user/setUser', _user).catch(err => { console.error(err) })
	  } else {
      firebase.auth().signOut()
      localStorage.removeItem('userData')
      router.replace('/')
	  }
  },
  data: function () {
    return {
      level1:[],
      level2:[],
      partner: [],
      showdetail: false,
      titleDetail: '',
      history:[],
    }
  },
  mounted () {
    this.scrollToTop()
    this.getPartner()
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    movePartnerToWallet () {
      if (this.partner.total && this.partner.key) {
        this.$store.commit('user/SET_LOADING', true)
        axios.get(`https://api.banana789.cloud/qpartner/partner/${this.partner.key}`)
        setTimeout(() => {
          this.getPartner()
          this.$store.commit('user/SET_LOADING', false)
        }, 2000)
      }
    },
    clipboardSuccessHandler () {
      this.$swal({ icon: 'success', title: 'Copy', text: 'คัดลอกเรียบร้อยแล้ว' })
    },
    checkStyle () {
      if (this.partner && this.partner.total > 0) {
        return 'background: linear-gradient(180deg,#fbc844,#f88012);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      } else {
        return 'background: linear-gradient(180deg,#cccccc,#cccccc);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      }
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    },
    numberWithCommas (x) {
	  if (!x) x = 0
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getPartner () {
      const user = this.getUserId()
      if (user) {
        firebase.firestore().collection('partner').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
          if (!snapshot.size) this.partner = []
          snapshot.forEach((doc) => {
            const data = doc.data()
            data.key = doc.id
            this.partner = data
          })
        })
        firebase.firestore().collection('friends').where('userkey', '==', user.key).get().then((result) => {
          result.forEach((doc) => {
            const data = doc.data()
            data.key = doc.id
            if(data.level === 1){
              this.level1.push(data)
            }else{
              this.level2.push(data)
            }
          })
        })
      }
    },
    async showlevel(level){
      const history = [];
      const deposit = [];
      const withdraw = [];
      if(level === 1){
        this.titleDetail = 'แนะนำเพื่อน';
        await firebase.firestore().collection('daily_deposit').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level1', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            deposit[doc.data().code] = doc.data().total;
          });
        });
        await firebase.firestore().collection('daily_withdraw').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level1', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            withdraw[doc.data().code] = doc.data().total;
          });
        });
        if(this.level1){
          for(const x in this.level1){
            if(deposit[this.level1[x].friend]){
              this.level1[x].deposit = deposit[this.level1[x].friend];
            }else{
              this.level1[x].deposit = 0;
            }
            if(withdraw[this.level1[x].friend]){
              this.level1[x].withdraw = withdraw[this.level1[x].friend];
            }else{
              this.level1[x].withdraw = 0;
            }
            this.level1[x].createdateShow = moment().add(-1,'days').format('DD MMM,YY')
            history.push(this.level1[x]);
          }
        }
      }else{
        this.titleDetail = 'เพื่อนแนะนำเพื่อน';
        await firebase.firestore().collection('daily_deposit').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level2', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            deposit[doc.data().code] = doc.data().total;
          });
        });
        await firebase.firestore().collection('daily_withdraw').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level2', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            withdraw[doc.data().code] = doc.data().total;
          });
        });
        if(this.level2){
          for(const x in this.level2){
            if(deposit[this.level2[x].friend]){
              this.level2[x].deposit = deposit[this.level2[x].friend];
            }else{
              this.level2[x].deposit = 0;
            }
            if(withdraw[this.level2[x].friend]){
              this.level2[x].withdraw = withdraw[this.level2[x].friend];
            }else{
              this.level2[x].withdraw = 0;
            }
            this.level2[x].createdateShow = moment().add(-1,'days').format('DD MMM,YY')
            history.push(this.level2[x]);
          }
        }
      }
      this.history = history;
      this.showdetail = true;
    },
    closeDetail(){
      this.showdetail = false;
    }
  }
}
</script>
<style scoped>
@media (max-width: 500px) {
    .x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-img-category {
        width: 45px;
        height: 45px;
    }
}
@media (max-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: .8rem;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent {
    display: flex;
}
}
@media (max-width: 990px) {
#main_content_recommend .x-category-index, #main_content_income .x-category-index {
    padding-top: 10px;
}
}

h1 {
  font-size:3em;
  font-weight: 300;
  line-height:1em;
  text-align: center;
  color: #4DC3FA;
}

/* h2 {
  font-size:1em;
  font-weight: 300;
  text-align: center;
  display: block;
  line-height:1em;
  padding-bottom: 2em;
  color: #FB667A;
}

h2 a {
  font-weight: 700;
  text-transform: uppercase;
  color: #FB667A;
  text-decoration: none;
} */
.blue { color: #185875; }
.yellow { color: #FFF842; }

.tablecontainer th h1 {
  font-family: 'Kanit';
	  /* font-weight: bold; */
	  font-size: 1.1em;
    text-align: left;
    color: #fff;
}
.tablecontainer td {
  font-family: 'Kanit';
	  font-weight: normal;
	  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0E1119;
	   -moz-box-shadow: 0 2px 2px -2px #0E1119;
	        box-shadow: 0 2px 2px -2px #0E1119;
}

.tablecontainer {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 0 8em 0;
}

.tablecontainer td, .tablecontainer th {
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left:2%;
}

/* Background-color of the odd rows */
.tablecontainer tr:nth-child(odd) {
  background-color: #ccc;
}

/* Background-color of the even rows */
.tablecontainer tr:nth-child(even) {
  background-color: #fff;
}

.tablecontainer th {
  background-color: #20c1f8;
}

.tablecontainer tr:hover {
  background-color: #464A52;
  -webkit-box-shadow: 0 6px 6px -6px #0E1119;
  -moz-box-shadow: 0 6px 6px -6px #0E1119;
      box-shadow: 0 6px 6px -6px #0E1119;
}

.tablecontainer td:hover {
  background-color: #FFF842;
  color: #403E10;
  font-weight: bold;

  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);

  transition-delay: 0s;
	  transition-duration: 0.4s;
	  transition-property: all;
  transition-timing-function: line;
}
.fixTableHead {
      overflow-y: auto;
      height: 500px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
.recommend_section input {
    border: 1px solid #fec33f;
    background: rgba(0,0,0,.5)!important;
    font-family: Kanit,sans-serif;
    color: #fff;
}
.btn_bg {
    background: linear-gradient(160deg,#b47e11,#fec33f,#e09c14);
    border-radius: 50px;
    border: none;
    padding: 6px;
}
</style>
