<template>
  <div>
    <headerSection mainMenu="promotion"></headerSection>
    <div id="main__content" class="">
            
<div class="x-cover x-index-event-container">
            <img src="../assets/images/cover-promotion-bg.jpg" class="-bg" alt="สูตรสล็อต สูตรบาคาร่า เจ้าแรกในไทย ผ่านเว็บตรง Banana789">
        <img src="../assets/images/cover-promotion-bg-mobile.jpg" class="-bg -mobile" alt="สูตรสล็อต สูตรบาคาร่า เจ้าแรกในไทย ผ่านเว็บตรง Banana789">

        <div class="container-fluid">
            <div class="row -contents-wrapper">
                <div class="col-12 col-md-6 -left-content"></div>
                <div class="col-12 col-md-6 -right-content bg-mobile">
                    <div data-slickable="{&quot;fade&quot;:true,&quot;arrows&quot;:false,&quot;autoplay&quot;:true,&quot;infinite&quot;:true,&quot;pauseOnHover&quot;:false,&quot;adaptiveHeight&quot;:true,&quot;slidesToShow&quot;:1,&quot;autoplaySpeed&quot;:5000}" data-animatable="fadeInUp" data-delay="200" class="slick-initialized slick-slider animated fadeInUp">
                                                    <div class="slick-list draggable" style="height: 58px;"><div class="slick-track" style="opacity: 1; width: 4495px; top: 5px; display: flex; justify-content: flex-end; float: right;">
                                                    <div class="slick-slide slick-current slick-active resize bg-mobile2" data-slick-index="0" aria-hidden="false" style="position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                                                    <h1 class="-title">โปรแรงเวอร์ กิจกรรมเพียบ <br>  จัดหนัก แรงที่สุดในตอนนี้</h1>
                                                                <p class="-sub-title">เกมส์เยอ์โปรโมชั่นดี อันดับ 1 ของจริง ไม่ใช่ทุกคนที่ทำได้</p>
                                <div class="-btn-wrapper d-none animated fadeInUp" data-animatable="fadeInUp" data-delay="500">
                                    <a href="javascript:void(0)" class="btn -register-btn order-0" data-toggle="modal" data-target="#registerModal" tabindex="0">
    <!-- <img src="https://ezslot.bet/build/web/ez-bet-v2/img/btn-register-bg.png" class="-btn-bg" alt="Banana789 สมัครสมาชิก" width="312" height="73"> -->
    <span class="-btn-title">สมัครสมาชิก</span>
</a>
                                </div>
                            </div></div></div>
                                            </div>
                </div>
            </div>
        </div>
    </div>
                                            
    <div class="x-event-index-container">
        <div class="container">
            <div class="row -event-row-wrapper">
                <div class="col-lg-4 col-6 -col-wrapper" v-for="(item,index) in banners">
                    <div class="-card-item animated fadeInUp" data-animatable="fadeInUp" data-delay="200">
                        <a class="d-block h-100 -card-link-wrapper">
                            <div class="x-card card -multi-card">
                                <div class="-img-container">
                                    <img :src="item.imagepro" :alt="item.name" class="-img">
                                </div>
                                <div class="card-body">
                                    <div class="-title-container m-3">
                                        <h3 class="-title">{{item.name}}</h3>
                                        <div class="-sub-title"><p>{{item.detail}}</p></div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
            

<div id="account-actions" class="-logged">
    <div class="x-button-actions" id="account-actions-mobile">
                    

    
<!-- <div class="d-flex">
    <div class="-btn-container -left">
        <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
            <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_withdraw_mobile.png" alt="Banana789 ถอนเงิน" class="-icon img-fluid">
            <div class="-typo">ถอนเงิน</div>
        </a>
    </div>
    <div class="-btn-container -center">
        <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
            <div class="-img-container">
                <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="Banana789 เข้าเล่น Lobby">
                <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="Banana789 เข้าเล่น Lobby">
            </div>
            <div class="-typo">หน้าเกมส์</div>
        </a>
    </div>
    <div class="-btn-container -right">
        <a href="javascript:void(0);" class="btn btn-lg btn-plain-secondary js-account-approve-aware" data-toggle="modal" data-target="#depositModal">
            <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_deposit_mobile.png" alt="Banana789 ฝากเงิน" class="-icon img-fluid">
            <div class="-typo">ฝากเงิน</div>
        </a>
    </div>
</div> -->
            </div>
</div>
                
<footerSection></footerSection>
    
            </div>


</div>
</template>

<script>
import router from './../router';
import firebase from 'firebase/app';
import moment from 'moment-timezone';
export default {
  name: 'Home',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  mounted() {
    // Bonn.boots.push(function() {
    //   setTimeout(function() {
    //     $('#bankInfoModal').modal('show');
    //   }, 500);
    // });
    this.$store.dispatch('user/getBanners').catch(err => { console.error(err) })
    //this.checkAnoucement()
  },
  computed: {
    banners() {
      return this.$store.state.user.banners;
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    lastbonus() {
      return this.$store.state.user.lastbonus;
    },
  },
  data() {
    return {
      continueCheck: false,
      receiveAnounceMent: false,
      anoucement: {},
      user: {},
      balance: 0,
      fa_spin: false,
    };
  },
  methods: {
    getUserId() {
      const user = localStorage.getItem('userData');
      if (user) {
        const _user = JSON.parse(user);
        return _user;
      } else {
        firebase.auth().signOut();
        localStorage.removeItem('userData');
        router.replace('/');
      }
    },
    nl2br(str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
      return (str + '').replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1' + breakTag + '$2'
      );
    },
    // async moveCreditToWallet () {
    //   this.$store.commit('user/SET_LOADING', true)
    //   const user = this.getUserId()
    //   this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
    //   this.$store.dispatch('user/getLastBonus', user).finally(res => {
    //     this.$store.commit('user/SET_LOADING', false)
    //     if (this.lastbonus.turncredit) {
    //       this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
    //     }
    //   }).catch(err => { console.error(err) })
    // },
  },
};
</script>
<style scoped>
@media screen and (max-width: 992px) {
    .resize {
        width: 899px;
    }
}
@media screen and (max-width: 600px) {
    .resize {
        width: 399px;
    }
}
.bg-mobile2{
  margin-right: 100px; 
}
@media screen and (max-width: 992px) {
    .resize {
        width: 899px;
    }
}
@media screen and (max-width: 600px) {
    .resize {
        width: 399px;
    }
    .bg-mobile{
      background-color: #1c1c1c;
    }
    .bg-mobile2{
      margin-right: 0; 
    }
}
</style>